import React, { useState } from "react";
import '../style/ConteudoHeader.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Autocomplete, Button, Drawer, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import IconButton from '@mui/material/IconButton';
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
const MAX_MENU_FIXO = 4
const teste1 = ["teste1", "teste2", "teste3", "teste4", "teste5", "teste6",]



export default function Header(props) {
    const { header, action, setTxtFiltro, leftMenu, body } = props;
    const isMax = useMediaQuery('(min-width:950px)');
    const [openMenu, setOpenMenu] = React.useState(null);
    const theme = useTheme();
    const [openPage, setOpenPage] = useState(true);
    const [busca, setBusca] = useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showInput, setShowInput] = React.useState(false);


    const [R, setMenuFixo] = useState();
    const [menuDinamico, setMenuDinamico] = useState();

    const [selectedIndex, setSelectedIndex] = useState(1);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const hadleHeaderPageClick = (item) => {
        // setShowPage(false)
        if (action) {
            action(item, true);
            setState({ ...state, ['right']: false });
            console.log('>>>ConteudoHeader<<<')
        }
    };

    const buscar = (dado) => {
        if (dado !== null) {

            let filtro;
            for (let x in body) {
                if (body[x].title.rendered === dado) {
                    filtro = body[x];
                }
            }

            hadleHeaderPageClick(filtro)
        }



    }
    const handleListItemClick = (event, item) => {
        setSelectedIndex(item.id);
        if (action) {
            action(item);
        }
        setOpenPage(!openPage);
    };


    const handleSearch = () => setShowInput((show) => !show);

    return (
        <AppBar style={{ backgroundColor: 'white', }} >
            <div className="header">
                <ul>
                    <li>
                        <img src="logo-ati.png" className="logo-ati" />
                    </li>
                    <li >
                        <a className="title-header">PlanCTIC</a>
                    </li>
                    <li >
                        <a className="subtitle-header">Portal Tutorial para Planejamento de Contratação de Tecnologia da Informação e Comunicação</a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <div >
                            {['right'].map((anchor, ii) => (
                                <React.Fragment key={"xx" + ii} >
                                    <IconButton color="primary" onClick={toggleDrawer(anchor, true)} aria-label="upload picture" component="label" className="icon-header" sx={{ right: showInput ? '-190px' : '45px' }}>
                                        <MenuIcon sx={{ fontSize: 29, color: "#1351B4" }} />
                                    </IconButton>

                                    <Drawer
                                        PaperProps={{
                                            sx: {
                                                backgroundColor: "#071D41",
                                                color: "red",
                                                width: '400px',
                                                alignItems: 'baseline',

                                            }
                                        }}
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        <div style={{ position: 'relative' }}>
                                            <h1 style={{ position: 'relative', color: '#FFFFFF', padding: '7px' }}>MENU</h1>
                                        </div>
                                        {isMax && header.map((h) => (
                                            <Button
                                                className="box"
                                                key={h.id}
                                                style={{
                                                    fontSize: '24px'
                                                }}
                                                sx={{
                                                    color: '#FFFFFF',
                                                    textTransform: 'none',
                                                    textAlign: 'left'
                                                }}
                                                onClick={() => hadleHeaderPageClick(h)}
                                            >
                                                {h.title.rendered}
                                            </Button>
                                        ))}


                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>
                    </li>
                    <li>
                        {showInput === false ?
                            <IconButton color="primary" aria-label="upload picture" component="label" className="icon-search-header" onClick={handleSearch}>
                                <SearchIcon sx={{ fontSize: 29, color: "#1351B4" }} />
                            </IconButton>
                            :
                            <div className="fild-text">

                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={body.map((options) => options.title.rendered)}
                                    renderInput={(params) => <TextField {...params} className="input-search" label="O que você procura?" />}
                                    value={busca}
                                    onChange={(event, newValue) => {
                                        buscar(newValue)
                                    }}
                                    sx={{
                                        width: 300,
                                        right: 50,
                                        position: "absolute"



                                    }}


                                />
                                {/* <TextField id="outlined-basic"variant="outlined" value={busca} className="input-search" onChange={(e) => setBusca(e.target.value)}></TextField> ? */}
                                <IconButton sx={{ right: '45px', top: '5px' }} color="primary" aria-label="upload picture" component="label" onClick={handleSearch}>
                                    <CloseIcon sx={{ fontSize: 29, color: "#1351B4" }} />
                                </IconButton>





                            </div>

                        }
                    </li>
                </ul>
            </div>
        </AppBar>
    )


}