import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import '../style/TimeLine.css'

export default function MontaLinkSubPagina(props){
    const {setPage, pagina, tags, setPageItens, setPageNav} = props;
    const [wait, setWait] = React.useState(true)
    const [exemplos, setExemplos] = React.useState()
    const [modelos, setModelos] = React.useState()
    const [boaPraticas, setBoaPraticas] = React.useState()

    React.useEffect(()=>{
        if( pagina){
            fetch('/api/?rest_route=/wp/v2/pages&per_page=99&parent=' + pagina.id)
                .then((response) => response.json())
                .then((data) => {
                    setWait(false)

                    setExemplos( filtro('exemplos', data));
                    setModelos(filtro('modelos', data));
                    setBoaPraticas(filtro('boasPraticas', data));
                });
        }
    }, [pagina, tags])

    function filtro(nome, data){
        const tag = tags.find( t => t.name === nome);
        if( tag){
            const itens = data.filter( d => d.tags.includes(tag.id));
            return itens && itens.length > 0 ?itens:null;
        }
        return null;
    }
    const menuItemClick=(filtro)=>{
        let arr = null;
        if( filtro === 'exemplos'){
            arr = [...exemplos]
        }else if( filtro === 'modelos'){
            arr = [...modelos]
        }else if( filtro === 'boasPraticas'){
            arr = [...boaPraticas]
        }

        if( arr === null) return;
        setPage(false);
        console.log( arr );
        arr.sort((a, b) => { return a.menu_order - b.menu_order })
        setPageItens(arr);
        setPageNav(pagina);   
        window.scrollTo(0, 0);
    }
    return (
        <ul>
            { exemplos    && <li onClick={() => menuItemClick('exemplos')}     style={{cursor: 'pointer' }}> <img src="icon1.jpeg" width={18} height={24} /><a className="links-name">Exemplos</a></li> }
            { modelos     && <li onClick={() => menuItemClick('modelos')}      style={{cursor: 'pointer' }}> <img src="icon2.jpeg" width={18} height={24} /><a className="links-name">Modelos</a></li> }
            { boaPraticas && <li onClick={() => menuItemClick('boasPraticas')} style={{cursor: 'pointer' }}> <img src="icon3.jpeg" width={18} height={24} /><a className="links-name">Boas Práticas</a></li> }
            { wait && 
            <li><CircularProgress /> </li> }
        </ul>
    )
}