import React, { useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box/Box";
import { Grid, Paper } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

import '../../style/TimeLine.css'
import { TimelineContent } from "@mui/lab";
import PageCard from "../PageCard";
import MontaLinkSubPagina from "../MontaLinkSubPagina";

export default function TimeLineComponent(props) {
    const isMax = useMediaQuery('(min-width:1100px)');
    const { rootPage, tags, setPageNav, pageNav, action } = props;

    const [corpo, setCorpo] = React.useState();
    const [page, setPage] = useState(true);
    const [pageItens, setPageItens] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [cards, setCards] = useState();

    React.useEffect(() => {
        if (pageNav.id === rootPage.id) {
            setPage(true)
        }
    }, [pageNav])

    React.useEffect(() => {
        function buscaCardFilho(id) {
            fetch('/api/?rest_route=/wp/v2/pages&per_page=99&parent=' + id)
                .then((response) => response.json())
                .then((data) => {
                    console.log("filho->", data)
                    const tag = tags.find(t => t.name === 'card');
                    if (tag) {
                        const itens = data.filter(d => d.tags.includes(tag.id));
                        if (itens) {
                            itens.sort((a, b) => { return a.menu_order - b.menu_order })
                            setCards(itens)
                        }
                    }
                });
        }
        if (rootPage && tags) {
            console.log('>>>>>', rootPage)
            fetch('/api/?rest_route=/wp/v2/pages&per_page=99&parent=' + rootPage.id)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    const tag = tags.find(t => t.name === 'root');
                    if (tag) {
                        const itens = data.filter(d => d.tags.includes(tag.id));
                        if (itens) {
                            itens.sort((a, b) => { return a.menu_order - b.menu_order })
                            setCorpo(itens)
                        }
                    }
                    if (data && data.length > 0) buscaCardFilho(data[0].id);
                });
        }

    }, [rootPage, tags])


    function calcMarginLeftItem(index) {
        switch (index) {
            case 0:
                return 11;
            case 1:
                return 9
        }
        return 11
    }
    function calcMarginLeftSuper(index) {
        switch (index) {
            case 0:
                return 47;
            case 4:
                return 35
        }
        return 35
    }
    function calcMarginLeft(index) {
        switch (index) {
            case 0:
                return 45;
            case 4:
                return 30
        }
        return 35
    }
    const MontaEtapaPai = () => {
        return (
            <Grid item xs={12}>
                <Timeline position="right" style={{ alignItems: "flex-start" }}>
                    {corpo && corpo.map((item, index) =>
                        <TimelineItem key={index}>
                            <TimelineSeparator >
                                <TimelineConnector />
                                <TimelineDot
                                    variant='outlined'
                                    color="info"
                                    // color='#1351B4'
                                    sx={{
                                        width: 131,
                                        height: 131,
                                    }}>
                                    <Typography variant="h6" component="span" color="info"
                                        style={{ marginLeft: calcMarginLeft(index), marginTop: -10, fontSize: 90, fontFamily: 'Poppins', color: "#1890FF" }}
                                    >
                                        {index + 1}
                                    </Typography>
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '24px', px: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        '& > :not(style)': {
                                            m: 1,
                                            width: 800,
                                            height: 400,
                                        },
                                    }}
                                >
                                    <Paper elevation={3} sx={{ backgroundColor: '#F6F6F2' }}>
                                        <Grid className="title" >
                                            <a className="tilte-text">{item.title.rendered}</a>
                                        </Grid>
                                        <Grid className="subtitle" >
                                            <a className="subtitle-text" dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }} />
                                        </Grid>
                                        <Grid className="links" >
                                            <MontaLinkSubPagina setPage={setPage} pagina={item} tags={tags} setPageItens={setPageItens} setPageNav={setPageNav} />
                                        </Grid>
                                    </Paper>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                </Timeline>

            </Grid>
        )
    }
    const MontaEtapaFilha = () => {
        return (
            <React.Fragment >
                <Grid item xs={10} >
                    <Timeline
                        position="right"
                        style={{ alignItems: "flex-start" }}
                    >
                        <TimelineItem>
                            <TimelineSeparator >
                                <TimelineDot
                                    variant='outlined'
                                    color="info"
                                    style={{
                                        marginTop: 170,
                                        backgroundColor: '#005EA2'
                                    }}
                                    sx={{
                                        width: 131,
                                        height: 131,

                                    }}
                                >
                                    <Typography variant="h6" component="span" color="info" style={{ marginLeft: calcMarginLeftSuper(pageIndex), marginTop: -10, fontSize: 90, fontFamily: 'Poppins', color: "#FFFFFF" }}>
                                        {pageIndex + 1}
                                    </Typography>
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '24px', px: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        '& > :not(style)': {
                                            m: 1,
                                            width: 700,
                                            height: 400,
                                        },
                                    }}
                                >
                                    <Paper elevation={3} sx={{ backgroundColor: '#F6F6F2' }}>
                                        <Grid className="title" >
                                            <a className="tilte-text">
                                                {pageItens[pageIndex].title.rendered}
                                            </a>
                                        </Grid>
                                        <Grid className="subtitle" >
                                            <a className="subtitle-text"
                                                dangerouslySetInnerHTML={{ __html: pageItens[pageIndex].excerpt.rendered }}
                                            />
                                        </Grid>
                                    </Paper>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                </Grid>
                <Grid item xs={2}>
                    <div>
                        <h1 className="title-stepper-side">Etapas</h1>
                    </div>
                    <Timeline>
                        {pageItens && pageItens.map((item, index) => (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot
                                        variant='outlined'
                                        color="info"
                                        style={{
                                            backgroundColor: pageIndex === index ? '#005EA2' : '#fff',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setPageIndex(index)}
                                        // color='#1351B4'
                                        sx={{
                                            width: 40,
                                            height: 40,

                                        }}>
                                        <Typography
                                            variant="h6"
                                            component="span"
                                            color="info"
                                            style={{ marginLeft: calcMarginLeftItem(index), marginTop: 1, fontSize: 16, fontFamily: 'Poppins', color: pageIndex === index ? "#FFFFFF" : "#005EA2" }}
                                        >
                                            {index + 1}
                                        </Typography>
                                    </TimelineDot>
                                    {1 + index < pageItens.length &&
                                        <TimelineConnector style={{ height: 30, color: 'red' }} />
                                    }
                                </TimelineSeparator>
                                <TimelineContent style={{
                                    marginTop: 14
                                }}>
                                    {item.title.rendered}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </Grid>
                {cards && cards.map((b) => (
                    <Grid item xs={isMax ? 3 : 4} key={b.id}>
                        <PageCard page={b} action={action} />
                    </Grid>
                ))}
            </React.Fragment>
        )
    }
    return (
        <Grid container spacing={0} >
            {page &&
                <MontaEtapaPai />
            }
            {page === false &&
                <MontaEtapaFilha />
            }

        </Grid>
    )
}