import { Grid } from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import '../style/ConteudoFooter.css';

export default function ConteudoFooter() {
    return (
        <div className="footer">

            <div className="midias-sociais">
                <ul className="sociais-pai">
                    <li style={{ position: 'absolute', top: 120, left: 80 }}><a>NOSSAS REDES</a></li>
                </ul>

                <ul className="flex-container flex-start">
                    <li className="flex-item"><img src="teste-logo.jpeg" style={{ height: '23px', width: '23px' }} /></li>
                    <li className="flex-item"><img src="facebook-logo.jpeg" style={{ height: '23px', width: '23px' }} /></li>
                    <li className="flex-item"><img src="twitter-logo.jpeg" style={{ height: '23px', width: '23px' }} /></li>
                    <li className="flex-item"><img src="insta-logo.jpeg" style={{ height: '23px', width: '23px' }} /></li>
                    {/* <li class="flex-item">5</li> */}
                </ul>

            </div>
            <div className="logo-ati">
                <a><img src="teste.jpeg" className="logo-ati-nova" /></a>
            </div>
           


        </div >

    )
}