import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';

import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HorizontalLinearStepper from './HorizontalLinearStepper';
import LeftListItem from './components/LeftListItem';
import palette from './theme/palette';
import ConteudoBody from './components/ConteudoBody';
import ConteudoHeader from './components/ConteudoHeader'

import './App.css';
import EndBarBody from './components/EndBarBody';
import ConteudoFooter from './components/ConteudoFooter';
import { convertLength } from '@mui/material/styles/cssUtils';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const theme = createTheme({
  palette,
  shape: { borderRadius: 8 },
});

const App = () => {
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [txtFiltro, setTxtFiltro] = useState();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [header, setHeader] = useState([]);
  const [headerFiltro, setHeaderFiltro] = useState([]);
  const [footer, setFooter] = useState([]);
  const [left, setLeft] = useState([]);
  const [leftFiltro, setLeftFiltro] = useState([]);
  const [wait, setWait] = useState(true)
  const [showPage, setShowPage] = useState(true);
  const [body, setBody] = useState([]);
  const [bodyFiltro, setBodyFiltro] = useState([]);
  const [page, setPage] = useState();
  const [rootPage, setRootPage] = useState();
  const [children, setChildren] = useState([]);
  const [currentHeader, setCurrentHeader] = useState();
  const [leftMenu, setLeftMenu] = useState([]);
  const [closeLeftMenu, setCloseLeftMenu] = useState(true);

  useEffect(() => {
    if (txtFiltro && header && left) {
      setPage(null);
      setRootPage(null);
      fetch("/api/?rest_route=/wp/v2/pages&search=" + txtFiltro)
        .then((response) => response.json())
        .then((data) => setBodyFiltro(data));
    } else {
      setBodyFiltro(body);
      let itens = body && body.filter(i => i.parent === 0);
      if (!itens || itens.length === 0) {
        itens = header.filter(i => i.parent === 0 && i.menu_order === 0);
        if (itens && itens.length > 0) {
          setPage(null);
          setRootPage(null);

          hadleHeaderPageClick(itens[0]);
        }
      } else {
        setBodyFiltro(body);
      }
    }
  }, [txtFiltro]);

  useEffect(() => {
    fetch('/api/?rest_route=/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data));
    fetch('/api/?rest_route=/wp/v2/tags')
      .then((response) => response.json())
      .then((data) => setTags(data));
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      montaLinkPaginaHeader();
      montaLinkPaginaLeft();
      montaLinkPaginaFooter();
    }
  }, [categories]);
  useEffect(() => {
    if (header && header.length > 0) {
      montaLinkPaginaBody();
    }
  }, [header]);
  useEffect(() => {
    if (leftFiltro) {
      let arr = [];
      for (let x in leftFiltro) {
        const cat = leftFiltro[x];
        if (cat.parent > 0 && !currentHeader) continue;
        if (currentHeader && cat.parent !== 0 && cat.parent !== currentHeader.id) {
          continue;
        }
        arr.push(leftFiltro[x])
      }
      arr.sort((a, b) => { return a.menu_order - b.menu_order })
      setLeftMenu(arr);
    }
  }, [currentHeader, left, header, leftFiltro]);

  const closeMenu = () => {
    setCloseLeftMenu(!closeLeftMenu)
  }

  const montaLinkPaginaBody = () => {
    const h = categories.find(c => c.name === 'Corpo');
    if (h) {
      setWait(true);
      fetch('/api/?rest_route=/wp/v2/pages&categories=' + h.id + '&per_page=99')
        .then((response) => response.json())
        .then((data) => {

          setWait(false);
          setBody(data)
          setBodyFiltro(data);

        });
    }
  }
  const montaLinkPaginaHeader = () => {
    const h = categories.find(c => c.name === 'Cabecalho');
    if (h) {
      fetch('/api/?rest_route=/wp/v2/pages&categories=' + h.id)
        .then((response) => response.json())
        .then((data) => {
          data.sort((a, b) => { return a.menu_order - b.menu_order })
          setHeader(data)
          setHeaderFiltro(data);
        });
    }
  }
  const montaLinkPaginaLeft = () => {
    const h = categories.find(c => c.name === 'MenuEsquerdo');
    if (h) {
      fetch('/api/?rest_route=/wp/v2/pages&categories=' + h.id)
        .then((response) => response.json())
        .then((data) => {
          setLeft(data);
          setLeftFiltro(data);
          let itens = data && data.filter(i => i.menu_order === 0);
          if (itens) {
            hadleHeaderPageClick(itens[0]);
          }
        });
    }
  }
  const montaLinkPaginaFooter = () => {
    const h = categories.find(c => c.name === 'footer');
    if (h) {
      fetch('/api/?rest_route=/wp/v2/pages&categories=' + h.id)
        .then((response) => response.json())
        .then((data) => setFooter(data));
      // console.log(data)

    }
  }

  const hadlePageClick = (p) => {
   
    setPage(p);
    setRootPage(null);
    setTxtFiltro('');
    fetch('/api/?rest_route=/wp/v2/pages/' + p.id)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)

        // const itens = bodyFiltro.filter(i => i.parent === p.id);
        // if (!itens || itens.length === 0) setPage(data);
        setRootPage(data);
      });
    getAllChilds(p.id)
  }
  const navegar = (p) => {
    if (p.parent === 0) {
      hadleHeaderPageClick(p);
    } else if (p.parent === -1) {
      window.location.reload();
    }
  }
  const hadlePageWithoutChildrenClick = (page) => {
    setTxtFiltro('');
    if (!page || !page.id) return;
    fetch('/api/?rest_route=/wp/v2/pages/' + page.id)
      .then((response) => response.json())
      .then((data) => setPage(data));
  }
  const hadleHeaderPageClick = (p, deveFechar) => {
    setWait( true );
    console.log('>>>hadleHeaderPageClick<<<', deveFechar )
    setCurrentHeader(p);
    // addHisotiro(p);
    setTxtFiltro('');
    setPage(p);
    fetch('/api/?rest_route=/wp/v2/pages/' + p.id)
      .then((response) => response.json())
      .then((data) => {
        setPage(data)
        setRootPage(data);
        if( deveFechar) setShowPage(false)
        setWait( false );
      });
    getAllChilds(p.id)
  }
  const getAllChilds = (id) => {
    fetch('/api/?rest_route=/wp/v2/pages&per_page=99&parent=' + id)
      .then((response) => response.json())
      .then((data) => setChildren(data));
  }
  function switchDackMode() {
    setCurrentTheme(
      currentTheme === theme ? darkTheme : theme
    )
  }
  return (
    <ThemeProvider theme={currentTheme}>

      < ConteudoHeader 
        header={headerFiltro} 
        leftMenu={leftMenu} 
        // showPage={showPage} 
        // setShowPage={setShowPage} 
        body={body} actionPage={hadlePageClick} children={children} action={hadleHeaderPageClick} closeLeftMenu={closeMenu} isMenuClose={closeLeftMenu} setTxtFiltro={setTxtFiltro} switchDackMode={switchDackMode} />
      <Grid container sx={{
        height: "90vh",
        backgroundColor: currentTheme.palette.mode === 'dark' ? 'black' : 'white',
      }}>
        {/* <Grid item xs={12} /> */}
        <Grid item xs={12} sx={{ marginTop: "130px" }}>
          <Grid item xs={12} sx={{ marginTop: '5px' }}>
            <HorizontalLinearStepper
              page={page}
              children={children}
              // pageHistory={pageHistory}
              action={hadlePageWithoutChildrenClick}
              navegar={navegar}
            />
          </Grid>
          <Container fixed maxWidth="lg">
            <ConteudoBody
              page={page}
              setPage={setPage}
              showPage={showPage}
              setShowPage={setShowPage}
              rootPage={rootPage} body={bodyFiltro}
              txtFiltro={txtFiltro} wait={wait}
              action={hadlePageClick}
              tags={tags}
            />
          </Container>
         
         
          <ConteudoFooter page={page} rootPage={rootPage} body={bodyFiltro} txtFiltro={txtFiltro} wait={wait} action={hadlePageClick} />
          <div style={{
            position: 'absolute',
            backgroundColor: '#f7f7f7',
            width: '100%',
            borderTop: 'solid 1px #AFAEA2'
          }}>
            <h3 className='copyright'>Copyright © 2020 ATI. Todos os Direitos Reservados</h3>
          </div>

          <div style={{
            position: 'absolute',
            backgroundColor: '#f7f7f7',
            width: '100%',
            borderTop: 'solid 1px #AFAEA2'
          }}>
            <h3 className='copyright'>Copyright © 2020 ATI. Todos os Direitos Reservados</h3>
          </div>
        </Grid>

      </Grid>
    </ThemeProvider>
  );
};
export default App;
