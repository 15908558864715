import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Stack, useTheme, CardActionArea, CardMedia, CardContent, Paper, CardHeader, IconButton, createTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../style/ConteudoBody.css';
import TimeLineComponent from '../components/timeLine/TimeLine'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button/Button';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import Card from '@mui/material/Card/Card';

import PageCard from './PageCard';
import RootPageCards from './RootPageCard';
import RootPage from './RootPage';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    primary: createColor('#1351B4'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3'),
  },
});



export default function ConteudoBody(props) {
  const isMax = useMediaQuery('(min-width:1100px)');
  const { page, rootPage, body, action, txtFiltro, wait, showPage, setShowPage, tags, setPage } = props;
  const [bodySort, setBodySort] = useState();
  const [showPageCards, setShowPageCards] = useState(true);

  const theme = useTheme();
  const [media, setMedia] = React.useState();

  useEffect(() => {
    // handleClick()
    if (body) {
      let arr = [...body];
      arr.sort((a, b) => a.menu_order - b.menu_order);

      if (txtFiltro) {
        setShowPageCards(true);
      } else if (rootPage) {
        arr = arr.filter(item => item.parent === rootPage.id);
        if (arr && arr.length > 0) setShowPageCards(true);
        else setShowPageCards(false);
      } else {
        arr = arr.filter(item => item.parent === 0);
        setShowPageCards(true);
      }
      setBodySort(arr);
    }
  }, [body, rootPage, txtFiltro])

  const showWait = () => {
    // if (!page && (!bodySort || bodySort.length === 0)) return true;
    // return false;
    return wait;
  }

  function handleClick() {
    if (action) action(page);

  }


  return (
    <div >

      {showWait() &&
        <Box sx={{ padding: '70px 0', textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      }


      {/* 
      <TimeLineComponent/> */}
      {showPage == true ?
        <section >
          <RootPage wait={wait} tags={tags} body={body} setShowPage={setShowPage} page={page} />

          <RootPageCards tags={tags} body={body} />

        </section>
        :
        <div >
          <Grid container spacing={0} >

            {page &&
              <Grid item xs={12} >
                <div >
                  <Typography variant="h5" gutterBottom >
                    {page.title.rendered}
                  </Typography>
                  <Stack direction="row" >
                    <div style={{ marginRight: 15 }} dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
                  </Stack>
                </div>
                <TimeLineComponent rootPage={rootPage} tags={tags} setPageNav={setPage} pageNav={page} action={action}/>
              </Grid>
            }

            {showPageCards && bodySort && bodySort.map((b) => (
              <Grid item xs={isMax ? 3 : 4} key={b.id}>
                <PageCard page={b} action={action} />
              </Grid>
            ))}
          </Grid>
        </div>
      }
    </div >
  );
}
