import React, { useState } from "react";
import { Typography, CardHeader } from '@mui/material';
import '../style/ConteudoBody.css';
import Button from '@mui/material/Button/Button';
import Card from '@mui/material/Card/Card';
import App from './carousel/index'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
}));
export default function RootPage(props) {

    const { body, wait, setShowPage } = props;
    return (
        <Stack spacing={2}>
            <Item>
                <App body={body} />
            </Item>
            <Item >
                <h1 style={{
                    textAlign: 'center'
                }}>Jornada de Contratação de Solução de TIC</h1>
                <img src='temporario.png' />
            </Item>

            <Item >
                <Button
                    disabled={wait}
                    onClick={() => {
                        setShowPage(false);
                        window.scrollTo(0, 0);
                    }} variant='contained'
                    style={{
                        borderRadius: 20,
                        // backgroundColor: "#21b6ae",
                        padding: "6px 56px",
                        fontSize: "18px"
                    }}
                >CONHECER A JORNADA</Button>
            </Item>
        </Stack>
    )
}