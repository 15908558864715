import { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme, styled } from '@mui/material/styles';
import {ListSubheader } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

const ListItemStyle = styled((props) => <ListItemButton  {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    // height: 48,
    // position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    // borderRadius: theme.shape.borderRadius,
}));

export default function LeftListItem(props) {
    const { leftMenu, action, children} = props;
    const [open, setOpen] = useState(true);

    const [selectedIndex, setSelectedIndex] = useState(1);
    const theme = useTheme();
    const activeRootStyle = {
        color: 'primary.main',
        // fontWeight: 'fontWeightMedium',  
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    };

    const handleListItemClick = (event, item) => {
        setSelectedIndex(item.id);
        if (action) {
            action(item);
        }
        setOpen(!open);
    };

    return (
        <List
            sx={{
                // width: '100%', 
                // maxWidth: 360, 
                bgcolor: 'background.paper',
                color: theme.palette.mode === 'dark'?'white':'#1351B4',
                marginTop: 1,

            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            
        >
            {/* {(!leftMenu || leftMenu.length ===0) && 
                <Skeleton variant="rounded" width={210} height={280} />
            } */}
            {leftMenu && leftMenu.map((item) => (
                <ListItemButton key={item.id} onClick={(event) => handleListItemClick(event, item)} 
                sx={{
                    borderBottom: 1,
                    // borderLeft: 2,
                    // borderRight: 2,
                    borderColor: '#919EAB',
                    // marginTop: 1,

                }}>
                    <ListItemText primary={item.title.rendered} />
                </ListItemButton>
                
            ))}
            

        </List>
    );
}
