import React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { Typography, CardHeader, IconButton, Button } from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import "../App.css";
export default function PageCard(props) {
  const { page, action } = props;

  const [media, setMedia] = React.useState('ex3.jpeg');

  React.useEffect(() => {
    if (page.featured_media !== 0) {
      fetch('/api/?rest_route=/wp/v2/media/' + page.featured_media)
        .then((response) => response.json())
        .then((data) => {
          setMedia(data.guid.rendered)
        });
    }
  }, [page])

  function handleClick() {
    if (action) action(page);
  }

  return (

    <div className="item">
      <Button onClick={() => handleClick()}>
        <Card sx={{ width: 280, height: 300, }}
        >
          <CardHeader

            title={page.title.rendered}
            subheader={
              <Typography variant="caption" color="text.secondary"> </Typography>
            }
          />
          {/* <img src='ex3.jpeg' width={288} height={137} /> */}
          {page.featured_media === 0 ?
            <Stack spacing={0}>

              <img src={media} width={270} height={180} />
              <Typography variant="caption" color="text.secondary"
                dangerouslySetInnerHTML={{ __html: page.excerpt.rendered.length > 20 ? page.excerpt.rendered.substr(20) : page.excerpt.rendered }}
              />
            </Stack>
            :
            <a href="#" onClick={() => handleClick()}>
              <img src={media} width={250} height={230}/>
            </a>
          }
        </Card>
      </Button>

    </div>

  );
}
