import React from "react";
import ReactDOM from "react-dom";
// import Carousel from "react-elastic-carousel";
import Slider from 'react-slick';

import "./styles.css";

const breakPoints = [
  { width: 550, itemsToShow: 1 },
  // { width: 550, itemsToShow: 2 },
  // { width: 768, itemsToShow: 3 },
  // { width: 1200, itemsToShow: 4 },
];

export default function App(props) {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };


  return (
    <div style={{ width: '800px'}} className="center">
      <Slider {...settings}>
        <div>
          <img src='temporario2.jpeg' width='100%' /> 
        </div>
        <div>
          <img src='temp1.jpeg' width='100%' /> 
        </div>
        <div>
          <img src='temp2.jpeg' width='100%' /> 
        </div>
      </Slider>
    </div>
  );
}


