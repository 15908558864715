import React, { useState } from "react";
import { Typography, CardHeader } from '@mui/material';
import '../style/ConteudoBody.css';
import Button from '@mui/material/Button/Button';
import Card from '@mui/material/Card/Card';

export default function RootPageCards(props) {

    const { page, body, action, txtFiltro, wait, showPage, setShowPage, tags } = props;

    const [cards, setCards] = React.useState();

    React.useEffect(() => {
        if (body && body.length > 0) {
            const tag = tags.find(t => t.name === 'card')
            if (tag) {
                const cds = body.filter(item => item.tags.includes(tag.id));
                cds.sort((a, b) => { return a.menu_order - b.menu_order })

                setCards(cds);
            }
        }
    }, [body, tags]);

    function handleClick() {
        if (action) action(page);

    }
    const MontaImagem=(props)=>{
        const {item} = props;
        const [imagem, setImagem] = React.useState('ex1.jpeg')
        if( item._links){
            const media = item._links['wp:featuredmedia'];
            if( media ){

                fetch( media[0].href )
                .then((response) => response.json())
                .then((data) => {
                    setImagem(data.source_url);

                });
            }

        }

        return <img src={imagem} width={288} height={137} />;
    }

    return (
        <div style={{
            marginTop: 100,
        }}>
            <section className="grid grid-template-columns-4">
                {cards && cards.map((item, i) => (
                    <div className="item" key={i}>

                    <Button>
                        <Card sx={{ width: 300, height: 300, }} onClick={() => console.log('aaa')}
                        >
                            <CardHeader

                                title={item.title.rendered}
                                subheader={
                                    <Typography variant="caption" color="text.secondary"></Typography>
                                }

                                onClick={() => console.log('bbb')}
                            />
                            {/* <img src='ex1.jpeg' width={288} height={137} /> */}
                            <MontaImagem item={item} />
                            <Typography variant="caption" color="text.secondary"
                                dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}
                            />
                                
                        </Card>
                    </Button>
                </div>
                    ))}
{/* 
                <div className="item">

                    <Button>
                        <Card sx={{ width: 300, height: 300, }} onClick={() => console.log('aaaaaaaaaaaaaaaaaaaaaa')}
                        >
                            <CardHeader

                                title={'page.title.rendered'}
                                subheader={
                                    <Typography variant="caption" color="text.secondary"></Typography>
                                }

                                onClick={() => console.log('aaaaaaaaaaaaaaaaaaaaaa')}
                            />
                            <img src='ex1.jpeg' width={288} height={137} />
                            <Typography variant="caption" color="text.secondary">teste</Typography>

                        </Card>
                    </Button>
                </div>
                <div className="item">
                    <Button>
                        <Card sx={{ width: 300, height: 300, }} onClick={() => console.log('aaaaaaaaaaaaaaaaaaaaaa')}
                        >
                            <CardHeader

                                title={'page.title.rendered'}
                                subheader={
                                    <Typography variant="caption" color="text.secondary"></Typography>
                                }
                                onClick={() => console.log('aaaaaaaaaaaaaaaaaaaaaa')}
                            />
                            <img src='ex2.jpeg' width={288} height={137} />
                            <Typography variant="caption" color="text.secondary">teste</Typography>

                        </Card>
                    </Button>
                </div>
                <div className="item">
                    <Button onClick={() => handleClick()}>
                        <Card sx={{ width: 300, height: 300, }}
                        >
                            <CardHeader

                                title={'page.title.rendered'}
                                subheader={
                                    <Typography variant="caption" color="text.secondary"> </Typography>
                                }
                            />
                            <img src='ex3.jpeg' width={288} height={137} />
                            <Typography variant="caption" color="text.secondary">teste</Typography>

                        </Card>
                    </Button>

                </div> */}

            </section>
        </div>
    )
}